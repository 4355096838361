<template>
    <div class="right">
        <div class="title">{{ title }}</div>
        <div v-for="(item, index) in datalist" :key="index" class="article" @click="todetails(item.id)">
          <div class="leftTop">
              <img :src="'/com/file/fileDownLoad?filePath=' + item.articleImage" alt="" class="leftTop"  :onerror="smallImgError">
          </div>
          <div class="articleright">
              <div class="smalltitle">
                {{item.title}}
              </div>
              <div class="releaseTime">
                {{item.releaseTime}}
              </div>
          </div>
        
        
        </div>
        <div class="pagination">
          <el-pagination background  :hide-on-single-page="total<=10" layout="prev, pager, next" :total="total" @current-change="handleChange" :current-page.sync="current" :page-size="size"></el-pagination>
        </div>
    </div>
</template>

<script>
import { pageArticleInfo } from "@/api/dynamicsOfCollegeEntranceExamination.js";
export default {
  data() {
    return {
      total: 0,
      current: 1,
      size: 10,
      datalist: [],//文章列表
      title: "",//文章类标题
      subjectId: "",
    };
  },
  watch:{
      $route(val){
          if(val.name == "dynamicsOfCollegeEntranceExaminationarticellist"){
              console.log(val,'val');
              this.subjectId = this.$route.query.subjectId
              this.getpageArticleInfo(this.subjectId)
          }
      }
  },
  mounted() {
    console.log('列表');
    this.subjectId = this.$route.query.subjectId
    this.getpageArticleInfo(this.subjectId)
  },
  methods: {
    getpageArticleInfo(subjectId) {
      pageArticleInfo({ subjectId: subjectId,type:3 }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.datalist = data.records || [];
          this.total = data.total || 0
          let obj = this.datalist[0] || {}
          this.title = obj.subject ? obj.subject : ""
        }
      });
    },
    // 去详情页
    todetails (id) {
      this.$router.push({path: '/dynamicsOfCollegeEntranceExamination/detial',query:{id:id}})
    },
    // 跳页
    handleChange (page) {
      console.log(page)
      this.current = page
      this.getpageArticleInfo(this.subjectId)
    },
  },
};
</script>

<style scoped lang="less">
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1ab394;
}
/deep/.el-pager li:hover {
    color: #1ab394!important;
}
.right {
  width: 960px;
  padding: 0 30px;
  .title {
    width: 960px;
    height: 106px;
    font-size: 26px;
    color: #222222;
    line-height: 106px;
    text-align: center;
  }
  .titleborder{
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 30px;
  }
  .article {
    display: flex;
    justify-content: flex-start;
    height: 106px;
    width: 960px;
    padding: 20px 0px;
    border-bottom: 1px solid #e6e6e6;
    .leftTop {
      height: 106px;
      width: 260px;
    }
    .articleright{
        padding: 10px 20px;
        .smalltitle{
            font-size: 18px;
            color: #333333;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .releaseTime{
            color: #999999;
            font-size: 14px;
        }
    }
  }
}
.pagination{
  margin-top: 40px;
}
</style>
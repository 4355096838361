import axios from '@/util/request'
//高考动态

// /mobile/content-info/getArticleSubjectList
// 栏目
export const getArticleSubjectList = (params) => {
    return axios({
      url: '/mobile/content-info/getArticleSubjectList',
      method: 'get',
      params
    })
}

// /mobile/content-info/pageArticleInfo
// 文章-获取分页列表(传参subjectId)
export const pageArticleInfo = (data) => {
  return axios({
    url: '/mobile/content-info/pageArticleInfo',
    method: 'post',
    data
  })
}

// /mobile/content-info/getArticleDetail
// 文章-获取单个详情
export const getArticleDetail = (params) => {
  return axios({
    url: '/mobile/content-info/getArticleDetail',
    method: 'get',
    params
  })
}